import React from "react";
import Item from "./Item/Item";
import css from "./WhyMe.module.scss";

const whyMe = [
    {
        title: "Почему именно я?",
        descr: "Я предоставляю качественную работу и в полной мере использую свои навыки для обеспечения необходимой вам помощи.",
    },
    {
        title: "Как записаться на приём?",
        descr: "Вы можете записаться на прием оставив свою заявку на сайте, также вы можете позвонить или написать на мой личный номер телефона, отписать мне в Instagram или Whatsapp, необходимые данные указанны на сайте. Мы подберём максимально удобное число и время для вас.",
    },
    {
        title: "С какими клиентами Вы работаете?",
        descr: "Я работаю с мужчинами и женщинами, достигшими совершеннолетнего возраста.",
    },
    {
        title: "Обязательно приходить на личный приём?",
        descr: "Если у вас нет возможности прийти на личный прием, то возможен сеанс на расстоянии, в виде звонка или переписки.",
    },
];

const WhyMe = () => {
    return (
        <div className={css.whyMe}>
            <p className={css.title}>Частые вопросы</p>
            <div className={css.arrowBlock}>
                {whyMe.map((item, index) => (
                    <Item index={index} item={item} key={index} />
                ))}
            </div>
        </div>
    );
};

export default WhyMe;
